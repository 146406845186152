@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');

/* fuente goose */
@font-face {
    font-family: 'GoodOT-CondBold';
    src: url('./assets/GoodOT-CondBold.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

/* fuente km24 */
@font-face {
    font-family: 'AmsiPro-Black';
    src: url('./assets/AmsiPro-Black.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

/* fuentes cusqueña */
@font-face {
    font-family: 'Norpeth-Bold';
    src: url('./assets/Norpeth_Bold.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RodfatTwo';
    src: url('./assets/Rodfat_Two.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GoodOT-CondBold';
    src: url('./assets/GoodOT-CondBold.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}


/* locked on modal open */
.locked {
    overflow: hidden;
    max-height: 100vh;
    pointer-events: none;
}

/* custom scrollbar - width */
.custom-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background-color: #ECEEF0;
    border-radius: 10px;
}

/*PRINT VERSION forced white printable*/
@media print {
    .forced-white-printable {
        @supports (-webkit-print-color-adjust: exact) {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
    }
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 10px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #0054A2;
}

.scroll-hidden::-webkit-scrollbar {
    display: none;
}